import { BaseStore } from "stores"
import { EnumMasterBenefitInsuranceType } from "constants/enums/master-benefit-insurance"

class BenefitInsuranceStore extends BaseStore {
  constructor() {
    super("benefitInsuranceStore", {
      selectedBenefitInsurancePlans: null,
      selectedBenefitInsurancePackage: null,
      benefitInsurance: null,
      benefitInsuranceRes: null,
      insuranceType: null,
      year: null,
    })
  }

  setSelectedBenefitInsurancePlan = (typeId, masterInsurancePlan) => {
    const previous = this.state.selectedBenefitInsurancePlans ? this.state.selectedBenefitInsurancePlans : {}
    const newItems = { ...previous, [typeId]: masterInsurancePlan }

    this.setState({
      ...this.state,
      selectedBenefitInsurancePlans: newItems,
    })
  }

  setSelectedBenefitInsurancePackage = (selectedPackage) => {
    this.setState({
      ...this.state,
      selectedBenefitInsurancePackage: selectedPackage,
    })
  }

  clearSelectedBenefitInsurancePlans = () => {
    this.setState({
      ...this.state,
      selectedBenefitInsurancePlans: null,
    })
  }

  clearSelectedBenefitInsurancePackage = () => {
    this.setState({
      ...this.state,
      selectedBenefitInsurancePackage: null,
    })
  }

  initialPreviewBenefitInsurance = (benefitInsurance) => {
    const { year, endDate, point, firstName, lastName, masterBenefitInsurances } = benefitInsurance
    if (masterBenefitInsurances.length > 0) {
      const { masterBenefitInsurancePlans, masterBenefitInsurancePackages, type } = masterBenefitInsurances?.[0]

      const benefitInsurancePlans = masterBenefitInsurancePlans?.filter((plan) => plan.isDefault)
      const benefitInsurancePackages = masterBenefitInsurancePackages?.filter((plan) => plan.isDefault)

      let insurances = []
      let requiredPoint = 0

      if (type === EnumMasterBenefitInsuranceType.custom) {
        insurances = benefitInsurancePlans.map((plan) => {
          const { masterInsurancePlan } = plan
          requiredPoint += Number(masterInsurancePlan.premium)

          return masterInsurancePlan.name
        })

        benefitInsurancePlans.forEach((plan) => {
          const { masterInsurancePlan } = plan
          if (
            !(
              this.selectedBenefitInsurancePlans &&
              this.selectedBenefitInsurancePlans[masterInsurancePlan.insuranceType.id]
            )
          ) {
            this.setSelectedBenefitInsurancePlan(masterInsurancePlan.insuranceType.id, {
              id: masterInsurancePlan.id,
              masterInsurancePlan,
              point: Number(masterInsurancePlan.premium),
            })
          }
        })
      } else if (type === EnumMasterBenefitInsuranceType.package) {
        insurances = benefitInsurancePackages.map(({ masterInsurancePackage }) => {
          masterInsurancePackage.insurancePackagesInsurancePlans.map(
            (insurance) => (requiredPoint += Number(insurance.insurance.premium)),
          )

          return masterInsurancePackage.name
        })

        benefitInsurancePackages.forEach(({ masterInsurancePackage }) => {
          if (!this.selectedBenefitInsurancePackage) {
            this.setSelectedBenefitInsurancePackage({
              id: masterInsurancePackage.id,
              masterInsurancePackage,
              point: Number(requiredPoint),
            })
          }
        })
      }

      const newBenefitInsurance = {
        year,
        lastDate: endDate,
        point: requiredPoint,
        maxPoint: point,
        insurances,
        type,
        firstName,
        lastName,
        fileBase64: benefitInsurance.fileBase64 ? benefitInsurance.fileBase64 : undefined,
        fileName: benefitInsurance.file?.path ? benefitInsurance.file?.path : undefined,
        fileObject: benefitInsurance.file ? benefitInsurance.file : undefined,
      }
      this.setState({
        ...this.state,
        benefitInsuranceRes: benefitInsurance,
        benefitInsurance: newBenefitInsurance,
        insuranceType: benefitInsurance.insuranceType,
        year: benefitInsurance.year,
      })
    }
  }
}

export default new BenefitInsuranceStore()
