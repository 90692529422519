import { InsuranceSelectorComponent, InsuranceSelectorProps, Insurance } from "./InsuranceSelector"
import { compose, withHooks, withTranslation, withStores } from "enhancers"
import { gql } from "@apollo/client"
import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as HeartWave } from "assets/icon/heart_wave.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as RadioActive } from "assets/icon/radio_active.svg"
import { ReactComponent as ShieldCheck } from "assets/icon/shield_check.svg"
import { Box, Carousel, Divider, Modal } from "components"
import Typography from "components/common/Typography"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import styled from "styled-components"
import Theme from "theme/custom"
import { paths, toCurrency } from "utils/helper"
import parse from "html-react-parser"
import benefitInsuranceStore from "stores/benefitInsuranceStore"

const API = {
  GET_MASTER_BENEFIT_INSURANCE: gql`
    query GET_MASTER_BENEFIT_INSURANCE($id: String!) {
      masterBenefitInsuranceInEdit(id: $id) {
        id
        type
        masterBenefitInsurancePackages {
          id
          requiredPoints
          masterInsurancePackage {
            id
            nameTh
            nameEn
            packagePricingType
            insurancePackagesInsurancePlans {
              id
              insurance {
                id
                nameTh
                nameEn
                premium
                remarkTh
                remarkEn
                description
              }
            }
          }
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePackage: stores.benefitInsuranceStore.selectedBenefitInsurancePackage,
  })),
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<InsuranceSelectorProps, "t"> => {
      const { useState, useCallback, useParams, useQuery, useMemo, useEffect, useDataTranslation, useUrlParam } = hooks
      const { t, selectedBenefitInsurancePackage } = props
      const { id } = useParams()
      const { masterInsurancePackageId } = useUrlParam()

      const { data: masterBenefitInsuranceData } = useQuery(API.GET_MASTER_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })

      const masterBenefitInsuranceDataTranslated = useDataTranslation(masterBenefitInsuranceData)

      const insurancePackages = useMemo(() => {
        if (masterBenefitInsuranceDataTranslated) {
          const response = masterBenefitInsuranceDataTranslated?.masterBenefitInsuranceInEdit?.masterBenefitInsurancePackages.map(
            (masterBenefitInsurancePackage: any) => {
              let description = ""
              for (const insurancePackagesInsurancePlan of masterBenefitInsurancePackage.masterInsurancePackage
                .insurancePackagesInsurancePlans) {
                description += insurancePackagesInsurancePlan.insurance.description
              }
              return {
                id: masterBenefitInsurancePackage.masterInsurancePackage.id,
                title: masterBenefitInsurancePackage.masterInsurancePackage.name,
                point: masterBenefitInsurancePackage.requiredPoints,
                description,
              }
            },
          )
          return response
        }
        return []
      }, [masterBenefitInsuranceDataTranslated])

      const [selected, setSelected] = useState("")

      const indexOfSelectedInsurance = insurancePackages.findIndex((element: any) => element.id === selected)

      // MOCK
      const insuranceType = {
        title: t(".package"),
      }

      const handleSelect = useCallback(
        (insurance: Insurance) => {
          Modal.open({
            children: (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ flexDirection: "column" }}>
                <EditLineIcon
                  width={50}
                  height={50}
                  style={{ marginTop: 0, marginBottom: "32px" }}
                  fill={Theme.colors["Primary/Primary Text"]}
                />
                <Typography variant="Header/16">{t(".selectInsurancePlan")}</Typography>
                <br />
                <Typography variant="Body/14">{t(".needToSelect", { name: insurance.title })}</Typography>
                <Box display="flex" alignItems="center" style={{ marginBottom: "16px" }}>
                  <PointCoinIcon style={{ marginRight: "8px" }} />
                  <Typography variant="Header/20">{`${toCurrency(insurance.point, { minimumFractionDigits: 0 })} ${t(
                    ".point",
                  )}`}</Typography>
                </Box>
              </Box>
            ),
            onOk: ({ close }: any) => {
              setSelected(insurance.id)
              if (masterBenefitInsuranceData) {
                close()
                const { masterBenefitInsuranceInEdit } = masterBenefitInsuranceData
                const { masterBenefitInsurancePackages } = masterBenefitInsuranceInEdit
                const selectedPackage = masterBenefitInsurancePackages.find(
                  (item: any) => item.masterInsurancePackage.id === insurance.id,
                )
                console.log(selectedPackage)
                if (selectedPackage) {
                  benefitInsuranceStore.setSelectedBenefitInsurancePackage({
                    id: selectedPackage.masterInsurancePackage.id,
                    masterInsurancePackage: selectedPackage.masterInsurancePackage,
                    point: selectedPackage.requiredPoints,
                  })
                }
              }
            },
            okButtonVariant: "contained",
            headerCloseButton: true,
            cancelButtonVariant: "outlined",
          })
        },
        [t, masterBenefitInsuranceData],
      )

      const handleClickBack = useCallback(() => {
        paths.benefitPackageManagementPath(id).push()
      }, [id])

      useEffect(() => {
        if (selectedBenefitInsurancePackage) {
          setSelected(selectedBenefitInsurancePackage.masterInsurancePackage.id)
        } else if (masterBenefitInsuranceData) {
          const { masterBenefitInsurancePackages } = masterBenefitInsuranceData.masterBenefitInsuranceInEdit

          const selectedPackage = masterBenefitInsurancePackages.find(
            (item: any) => item.masterInsurancePackage.id === masterInsurancePackageId,
          )
          if (selectedPackage) {
            benefitInsuranceStore.setSelectedBenefitInsurancePackage({
              id: selectedPackage.masterInsurancePackage.id,
              masterInsurancePackage: selectedPackage.masterInsurancePackage,
              point: selectedPackage.requiredPoints,
            })
          }
        }
      }, [selectedBenefitInsurancePackage, masterInsurancePackageId, masterBenefitInsuranceData])

      return {
        indexOfSelectedInsurance,
        handleClickBack,
        handleSelect,
        insurances: insurancePackages,
        selected,
        header: insuranceType.title,
      }
    },
  ),
)

export const PackageSelectorPage = enhancer(InsuranceSelectorComponent)
