import { ReactComponent as AlertSignIcon } from "assets/icon/alert_sign-icon.svg"
import { ReactComponent as Clound } from "assets/icon/cloud upload.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { Box, Button, Divider, Grid, Modal } from "components"
import T from "components/common/T"
import Typography from "components/common/Typography"
import { compose, withHooks, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { get } from "lodash"
import styled, { CSSProperties } from "styled-components"
import Theme from "theme/custom"
import { gql, toCurrency } from "utils/helper"
import { ConsentModal } from "./Consent"
import { PackagePricingType } from "constants/enums/package-pricing-type"
import { useMemo } from "react"
import { ContactlessOutlined } from "@material-ui/icons"
const Container = styled("div")`
  background-color: ${Theme.colors["Text/Placeholder"]};
  .content {
    padding: 16px 16px 24px 16px;
  }
  .detail-container {
    border: 1px solid ${Theme.colors["White / White"]};
    border-radius: 4px;
    padding: 16px;
    margin: 16px 0px;
  }
`

interface FooterSummaryProps {
  t: TFunction
  availablePoint: string
  usingPoint: string
  totalPoint: string
  showModal: () => void
  showModalCloud: () => void
  onSubmit: () => void
  isPreview: boolean
}

const flexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}

const FooterSummaryComponent = ({
  t,
  availablePoint,
  usingPoint,
  totalPoint,
  showModal,
  isPreview,
  showModalCloud,
}: FooterSummaryProps) => (
  <Container>
    <div className="content">
      <Typography color={Theme.colors["White / White"]} variant="h6">
        {t(".summaryPoint")}
      </Typography>
      <div className="detail-container">
        <Grid container>
          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {t(".availablePoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ ...flexStyle, marginBottom: 16 }}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={{ ...flexStyle, marginBottom: 16 }}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {availablePoint}
            </Typography>
          </Grid>

          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {t(".usingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={flexStyle}>
            <Typography color={Theme.colors["White / White"]} variant="subtitle2">
              {`-${usingPoint}`}
            </Typography>
          </Grid>

          <Divider style={{ width: "100%", marginTop: 16, marginBottom: 16 }} />

          <Grid item xs={7}>
            <Typography color={Theme.colors["White / White"]} variant="h5">
              {t(".remainingPoint")}
            </Typography>
          </Grid>
          <Grid item xs={2} style={flexStyle}>
            <PointCoinIcon width={16} height={16} />
          </Grid>
          <Grid item xs={3} style={flexStyle}>
            <Typography color={Theme.colors["White / White"]} variant="h5">
              {totalPoint}
            </Typography>
          </Grid>
        </Grid>
      </div>
      {!isPreview && (
        <>
          <Button style={{ border: "none" }} onClick={showModal}>
            {t(".confirm")}
          </Button>
          <div style={{ marginTop: "16px", font: "white", display: "flex", justifyContent: "center", gap: "4px" }}>
            <Button
              color={Theme.colors["White / White"]}
              style={{ border: "none", backgroundColor: "transparent", width: "100%" }}
              onClick={showModalCloud}
            >
              <Clound style={{ marginRight: "4px" }} />
              {t(".clound")}
            </Button>
          </div>
        </>
      )}
    </div>
  </Container>
)
const API = {
  validatePoints: gql`
    mutation validatePoints(
      $benefitInsuranceId: String
      $masterBenefitInsurancePlan: JSON
      $id: String
      $points: Float
      $selectedBenefitInsurancePackage: JSON
    ) {
      validateAttendeePoints(
        input: {
          benefitInsuranceId: $benefitInsuranceId
          masterBenefitInsurancePlan: $masterBenefitInsurancePlan
          id: $id
          points: $points
          selectedBenefitInsurancePackage: $selectedBenefitInsurancePackage
        }
      )
    }
  `,
}

const enhancer = compose(
  withTranslation({ prefix: "pages.main.benefit.FooterSummary" }),
  withHooks(
    (props: any, hooks: any): Omit<FooterSummaryProps, "t"> => {
      const { useMutation, useState } = hooks
      const {
        availablePoint,
        usingPoint,
        onSubmit,
        isPreview,
        masterBenefitInsurancePlan,
        benefitInsuranceResTranslated,
        benefitInsurance,
        selectedBenefitInsurancePackage,
        id,
        t,
      } = props
      const [forceCompleteState, setForceCompleteState] = useState(false)
      const [validateAttendeePoint, { data }] = useMutation(API.validatePoints, {
        onCompleted: (data: any) => {
          Modal.open({
            children: <ConsentModal onSubmit={() => onSubmit(forceCompleteState)} />,
            fullWidth: false,
            hideFooter: true,
          })
        },
        skipSetError: true,
        onError: (error: any) => {
          const errors = get(error, "networkError.result.errors")
          const errorsResponse = errors[0].extensions.originalError[0].errorResponse
          const titleError = errorsResponse?.titleError
          const messageError = errorsResponse?.messageError
          const confirmError = errorsResponse?.confirmError
          Modal.alert({
            title: " ",
            children: (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                  <AlertSignIcon />
                  <Box mt={8}>
                    <T variant="Header/16" color="Gray/Primary Text2">
                      {titleError}
                    </T>
                  </Box>
                  <Box mt={4}>
                    <T variant="Body/14" color="Gray/Secondary Text">
                      {messageError}
                    </T>
                  </Box>
                </Box>
              </>
            ),
            onClose: handleCloseModal,
            headerCloseButton: true,
            okButtonLabel: (
              <Button style={{ border: "none" }}>
                <T>{confirmError}</T>
              </Button>
            ),
          })
        },
      })
      const handleCloseModal = (data: any) => {
        Modal.closeAlertModal()
      }

      const showModalCloud = () => {
        Modal.open({
          title: "",
          children: (
            <>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={4} mt={10}>
                <Clound style={{ width: "72px", height: "72px" }} fill={Theme.colors["Primary/Primary Text"]} />
                <Box mt={8}>
                  <Typography variant="Header/16" color="Gray/Primary Text">
                    {t(".modalForceCompleteText1")}
                  </Typography>
                </Box>
                <Box mt={4}>
                  <Typography variant="Body/14" color="Text/Secondary Text">
                    {t(".modalForceCompleteText2")}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="h5" color="Error/Error Text">
                    {t(".modalForceCompleteText3")}
                  </Typography>
                </Box>
              </Box>
            </>
          ),
          onClose: Modal.close(),
          headerCloseButton: true,
          cancelButtonLabel: "ยกเลิก",
          cancelButtonVariant: "outlined",
          okButtonLabel: "ยืนยันแผนประกัน",
          okButtonVariant: "contained",
          onOk: () => {
            setForceCompleteState(true)
            showModal()
          },
        })
      }
      const showModal = () => {
        validateAttendeePoint({
          variables: {
            benefitInsuranceId: benefitInsuranceResTranslated?.benefitInsurance?.id ?? benefitInsurance?.id,
            masterBenefitInsurancePlan: masterBenefitInsurancePlan ?? {},
            // Use in Packages
            id,
            points: selectedBenefitInsurancePackage?.point,
            selectedBenefitInsurancePackage: selectedBenefitInsurancePackage ?? {},
          },
        })
      }
      const calculatedPoint = useMemo(() => {
        if (
          selectedBenefitInsurancePackage?.masterInsurancePackage?.packagePricingType ===
          PackagePricingType.ALL_REMAINING
        ) {
          return 0
        } else {
          return availablePoint - usingPoint
        }
      }, [selectedBenefitInsurancePackage?.masterInsurancePackage?.packagePricingType, availablePoint, usingPoint])
      return {
        availablePoint: toCurrency(availablePoint, { minimumFractionDigits: 0 }),
        usingPoint: toCurrency(usingPoint, { minimumFractionDigits: 0 }),
        totalPoint: toCurrency(calculatedPoint, { minimumFractionDigits: 0 }),
        onSubmit,
        showModal,
        isPreview,
        showModalCloud,
      }
    },
  ),
)

export const FooterSummary = enhancer(FooterSummaryComponent)
