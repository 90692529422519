import { findKey } from "lodash"
import { isClient } from "common/helper"
import { cleanEnv, str } from "envalid"

// console.log("process.env", process.env)

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str(),
  REACT_APP_DEFAULT_LANG: str(),
  NODE_ENV: str(),
})

const config = {
  production: {
    HOST: "https://mybenefit-app-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://mybenefit-app-flexben-uat.scg.com",
    API_ENDPOINT: "https://mybenefit-app-flexben-uat.scg.com/graphql",
    API: "https://mybenefit-api-flexben-uat.scg.com",
  },
  staging: {
    HOST: "https://dev.app.my-benefit.ldrive.io",
    API_ENDPOINT: "https://dev.app.my-benefit.ldrive.io/graphql",
    API: "https://dev.api.my-benefit.ldrive.io",
  },
  development: {
    HOST: "http://host.docker.internal:21703",
    API_ENDPOINT: "http://host.docker.internal:21701/graphql",
    API: "http://host.docker.internal:21701",
  },
  development2: {
    HOST: "http://localhost:21703",
    API_ENDPOINT: "http://localhost:21701/graphql",
    API: "http://localhost:21701",
  },
}

export const getEnvByHost = () => (isClient ? findKey(config, { HOST: window.location.origin }) : null)

export const env = getEnvByHost() || "production"

console.log("ENV : ", getEnvByHost())
console.log("HOST : ", window.location.origin)
console.log("GET HOST : ", config[env].HOST)

const backofficeEnvConfig = {
  production: {
    HOST: "https://mybenefit-backoffice-flexben.scg.com",
    API_ENDPOINT: "https://mybenefit-backoffice-flexben.scg.com/graphql",
    API: "https://mybenefit-api-flexben.scg.com",
  },
  uat: {
    HOST: "https://mybenefit-backoffice-flexben-uat.scg.com",
    API_ENDPOINT: "https://mybenefit-backoffice-flexben-uat.scg.com/graphql",
    API: "https://mybenefit-api-flexben-uat.scg.com",
  },
  staging: {
    HOST: "https://dev.backoffice.my-benefit.ldrive.io",
    API_ENDPOINT: "https://dev.backoffice.my-benefit.ldrive.io/graphql",
    API: "https://dev.api.my-benefit.ldrive.io",
  },
  development: {
    HOST: "http://host.docker.internal:21702",
    API_ENDPOINT: "http://host.docker.internal:21701/graphql",
    API: "http://host.docker.internal:21701",
  },
  development2: {
    HOST: "http://localhost:21702",
    API_ENDPOINT: "http://localhost:21701/graphql",
    API: "http://localhost:21701",
  },
}

export const backofficeEnv = backofficeEnvConfig[env]

export default config[env]
