import MuiTypography, { TypographyProps } from "@material-ui/core/Typography"
import { Variant } from "@material-ui/core/styles/createTypography"
import { isNaN, isNumber, isString } from "lodash"
import { CSSProperties, PropsWithChildren, useMemo } from "react"

import Theme from "theme/custom"
import typography from "theme/typography"
import { toCurrency } from "utils/helper"

const CustomVariant = {
  "Body/14": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "Body/16": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "Header/16": {
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "32px",
  },
  "Header/20": {
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "32px",
  },
  "Header/24": {
    fontSize: "24px",
    fontWeight: 800,
    lineHeight: "32px",
  },
  "Header/32": {
    fontSize: "32px",
    fontWeight: 800,
    lineHeight: "52px",
  },
  "Subtitle/14": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  "Subtitle/16": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "26px",
  },
}

const customVariantArr = Object.keys(CustomVariant).join(",")

interface CustomTypographyProps extends Pick<TypographyProps, Exclude<keyof TypographyProps, "color" | "variant">> {
  isCurrency?: boolean
  minimumFractionDigits?: number
  maximumFractionDigits?: number
  color?: keyof typeof Theme.colors | string

  variant: Variant | keyof typeof CustomVariant
}

const Typography = (props: PropsWithChildren<CustomTypographyProps>) => {
  const {
    children,
    color,
    style,
    variant,
    isCurrency = false,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    ...rest
  } = props
  const isHexa = color?.startsWith("#")
  const customColor = isHexa ? color : Theme.colors[color as keyof typeof Theme.colors]

  const customVariant = customVariantArr.includes(variant) && CustomVariant[variant as keyof typeof CustomVariant]
  const variantStyle: CSSProperties | undefined =
    customVariantArr.includes(variant) && customVariant
      ? {
          fontSize: customVariant.fontSize,
          fontWeight: customVariant.fontWeight,
          lineHeight: customVariant.lineHeight,
          fontFamily: typography.fontFamily,
        }
      : undefined

  let customRestProps = customColor ? { ...rest, style: { ...style, color: customColor } } : { ...rest, style }

  if (customVariant) customRestProps = { ...customRestProps, style: { ...customRestProps.style, ...variantStyle } }

  const customChildren = useMemo(() => {
    if (isCurrency && children) {
      if (isString(children))
        return children.replace(/\d+/g, (match) => toCurrency(match, { minimumFractionDigits, maximumFractionDigits }))
      else if (isNumber(children)) {
        return isNaN(children) ? 0 : toCurrency(children, { minimumFractionDigits, maximumFractionDigits })
      }
    }
    return isNaN(children) ? 0 : children
  }, [isCurrency, children, minimumFractionDigits, maximumFractionDigits])

  return (
    <MuiTypography variant={!customVariant ? (variant as Variant) : "inherit"} {...customRestProps}>
      {customChildren}
    </MuiTypography>
  )
}

export default Typography
