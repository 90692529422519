import { ReactComponent as EditLineIcon } from "assets/icon/edit_line_icon.svg"
import { ReactComponent as HeartWave } from "assets/icon/heart_wave.svg"
import { ReactComponent as PointCoinIcon } from "assets/icon/point_coin_icon.svg"
import { ReactComponent as RadioActive } from "assets/icon/radio_active.svg"
import { ReactComponent as ShieldCheck } from "assets/icon/shield_check.svg"
import { Box, Carousel, Divider, Modal } from "components"
import Typography from "components/common/Typography"
import { compose, withHooks, withStores, withTranslation } from "enhancers"
import { TFunction } from "i18next"
import { PageContent } from "layouts"
import styled from "styled-components"
import Theme from "theme/custom"
import { gql, paths, toCurrency } from "utils/helper"
import parse from "html-react-parser"
import { useTranslation } from "react-i18next"
import benefitInsuranceStore from "stores/benefitInsuranceStore"
import { element } from "prop-types"

type Selected = "selected" | "unselected"

const InsuranceSelectorContainer = styled("div")<{ selected: Selected }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  border: 1px solid ${(props) => selectButtonConfig[props.selected].containerBorderColor};
  border-top: 8px solid ${(props) => selectButtonConfig[props.selected].containerBorderColor};
  border-bottom: 8px solid ${(props) => selectButtonConfig[props.selected].containerBorderColor};
  margin: 0px 8px;
  padding-top: 12px;
  padding-bottom: 40px;
  .point-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
  }
`

const selectButtonConfig = {
  selected: {
    fontColor: Theme.colors["White / White"],
    backgroundColor: Theme.colors["Primary/Primary Text"],
    border: "none",
    containerBorderColor: Theme.colors["Primary/Primary Text"],
    footerFontColor: Theme.colors["Primary/Primary Text"],
    footerBackground: Theme.colors["Primary/Background"],
  },
  unselected: {
    fontColor: Theme.colors["Text/Primary Text"],
    backgroundColor: Theme.colors["White / White"],
    border: "1px solid",
    containerBorderColor: Theme.colors["Text/Disable"],
    footerFontColor: Theme.colors["Text/Secondary Text"],
    footerBackground: Theme.colors["Text/Background"],
  },
}

const SelectButton = styled("div")<{ selected: Selected }>`
  display: flex;
  align-items: center;
  color: ${(props) => selectButtonConfig[props.selected].fontColor};
  background-color: ${(props) => selectButtonConfig[props.selected].backgroundColor};
  border: ${(props) => selectButtonConfig[props.selected].border};
  border-radius: 4px;
  padding: 8px;
  .icon-container {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`

const UncheckedRadio = styled("div")`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid ${Theme.colors["Gray/Primary Text"]};
`

const BenefitContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 32px;
  max-width: 50%;
  text-align: center;
  margin-bottom: 32px;
  font-family: LINESeedSansTH;
`

const ItemFooter = styled("div")<{ selected: Selected }>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40px;
  background-color: ${(props) => selectButtonConfig[props.selected].footerBackground};
  .content {
    display: flex;
    justify-content: center;
    padding: 8px 28px;
  }
`

export interface Insurance {
  id: string
  title: string
  point: number
  description: string
}

interface InsuranceSelectorItemProps {
  t: TFunction
  selected: boolean
  insurance: Insurance
  onSelect: (insurance: Insurance) => void
}

const InsuranceSelectorItem = ({ t, onSelect, selected, insurance }: InsuranceSelectorItemProps) => {
  const selectedProps = selected ? "selected" : "unselected"
  const { id, point, title, description } = insurance
  return (
    <InsuranceSelectorContainer selected={selectedProps}>
      <Typography variant="h5">{title}</Typography>
      <br />
      <div className="point-container">
        <PointCoinIcon style={{ marginRight: "8px" }} />
        <Typography variant="Header/20">{`-${toCurrency(point, { minimumFractionDigits: 0 })} ${t(
          ".point",
        )}`}</Typography>
      </div>
      <SelectButton onClick={() => !selected && onSelect(insurance)} selected={selectedProps}>
        <div className="icon-container">{selected ? <RadioActive width={16} height={16} /> : <UncheckedRadio />}</div>
        <Typography variant="h3">{t(".selectInsurance")}</Typography>
      </SelectButton>
      <Divider
        style={{
          width: "100%",
          marginTop: "8px",
          marginBottom: "32px",
          backgroundColor: selectButtonConfig[selectedProps].containerBorderColor,
        }}
      />
      <BenefitContainer>{parse(description || "")}</BenefitContainer>
      <ItemFooter selected={selectedProps}>
        <div className="content">
          <HeartWave fill={selectButtonConfig[selectedProps].footerFontColor} style={{ marginRight: "8px" }} />
          <Typography variant="h5" color={selectButtonConfig[selectedProps].footerFontColor}>
            {t(".insuranceYourSelect")}
          </Typography>
        </div>
      </ItemFooter>
    </InsuranceSelectorContainer>
  )
}

const Header = styled("div")`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Theme.colors["Primary/Background"]};
  margin-bottom: 24px;
`

export interface InsuranceSelectorProps {
  t: TFunction
  handleClickBack: () => void
  handleSelect: (insurance: Insurance) => void
  indexOfSelectedInsurance?: number
  insurances: Insurance[]
  selected: string
  header: string
}

export const InsuranceSelectorComponent = ({
  t,
  indexOfSelectedInsurance,
  handleClickBack,
  handleSelect,
  insurances,
  selected,
  header,
}: InsuranceSelectorProps) => {
  return (
    <PageContent
      title={t(".title")}
      onBack={handleClickBack}
      showActionFooter
      childrenPadding="0px"
      bottomSpace="20%"
      childrenMarginTop="-80px" //ระยะห่างระหว่างpagecontent ที่เกินอันนี้ช่วยได้
      type="secondary"
    >
      <Header>
        <ShieldCheck style={{ marginRight: "8px" }} fill={Theme.colors["Primary/Primary Text"]} />
        <Typography variant="h3">{header}</Typography>
      </Header>
      <Box justifyItems="center">
        {insurances.length === 0 ? (
          <></>
        ) : (
          <Carousel
            mode="Multiple"
            defaultIndex={indexOfSelectedInsurance}
            sliderItems={insurances.map((insuranceItem) => (
              <InsuranceSelectorItem
                t={t}
                onSelect={handleSelect}
                selected={selected === insuranceItem.id}
                insurance={insuranceItem}
              />
            ))}
          />
        )}
      </Box>
    </PageContent>
  )
}

const API = {
  GET_INSURANCE_TYPE: gql`
    query GET_INSURANCE_TYPE($id: String!) {
      insuranceType(id: $id) {
        id
        symbol
        nameTh
        nameEn
      }
    }
  `,
  GET_MASTER_BENEFIT_INSURANCE: gql`
    query GET_MASTER_BENEFIT_INSURANCE($id: String!) {
      masterBenefitInsuranceInEdit(id: $id) {
        masterBenefitInsurancePlans {
          id
          requiredPoints
          masterInsurancePlan {
            id
            nameTh
            nameEn
            premium
            remarkTh
            remarkEn
            description
            insuranceType {
              id
              symbol
              nameTh
              nameEn
            }
          }
        }
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    selectedBenefitInsurancePlans: stores.benefitInsuranceStore.selectedBenefitInsurancePlans,
  })),
  withTranslation({ prefix: "pages.main.benefit.InsuranceSelector" }),
  withHooks(
    (props: any, hooks: any): Omit<InsuranceSelectorProps, "t"> => {
      const { useState, useCallback, useParams, useUrlParam, useQuery, useEffect, useMemo, useDataTranslation } = hooks
      const { t, selectedBenefitInsurancePlans } = props
      const { i18n } = useTranslation()
      const { language } = i18n
      const { id } = useParams()
      const { insuranceTypeId, masterInsurancePlanId } = useUrlParam()

      const { data: masterBenefitInsuranceRes } = useQuery(API.GET_MASTER_BENEFIT_INSURANCE, {
        variables: { id },
        fetchPolicy: "network-only",
      })
      const { data: insuranceType } = useQuery(API.GET_INSURANCE_TYPE, {
        variables: { id: insuranceTypeId },
        fetchPolicy: "network-only",
      })

      const [selected, setSelected] = useState("")
      const masterBenefitInsuranceResTranslated = useDataTranslation(masterBenefitInsuranceRes)
      const insuranceTypeTranslated = useDataTranslation(insuranceType)

      const insuranceTypeName = useMemo(
        () => (insuranceTypeTranslated ? insuranceTypeTranslated.insuranceType.name : ""),
        [insuranceTypeTranslated],
      )

      const insurances: Insurance[] = useMemo((): Insurance[] => {
        if (masterBenefitInsuranceResTranslated) {
          const { masterBenefitInsuranceInEdit } = masterBenefitInsuranceResTranslated
          const { masterBenefitInsurancePlans } = masterBenefitInsuranceInEdit
          const response = masterBenefitInsurancePlans
            .filter((plan: any) => plan.masterInsurancePlan.insuranceType.id === insuranceTypeId)
            .map(
              (plan: any): Insurance => ({
                id: plan.masterInsurancePlan.id,
                description: plan.masterInsurancePlan.description,
                point: Number(plan.requiredPoints),
                title: plan.masterInsurancePlan.name,
              }),
            )

          return response
        }
        return []
      }, [masterBenefitInsuranceResTranslated, insuranceTypeId])

      const indexOfSelectedInsurance = insurances.findIndex((element: any) => element.id === selected)

      const handleSelect = useCallback(
        (insurance: Insurance) => {
          Modal.open({
            children: (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ flexDirection: "column" }}>
                <EditLineIcon
                  width={50}
                  height={50}
                  style={{ margin: 40 }}
                  fill={Theme.colors["Primary/Primary Text"]}
                />
                <Typography variant="Header/16">{t(".selectInsurancePlan")}</Typography>
                <br />
                <Typography variant="Body/14" align="center">
                  {t(".needToSelect", { name: insurance.title })}
                </Typography>
                <Box display="flex" alignItems="center">
                  <div className="point-container" style={{ display: "flex", alignItems: "center" }}>
                    <PointCoinIcon style={{ marginRight: "8px" }} />
                    <Typography variant="Header/20">{`${toCurrency(insurance.point, { minimumFractionDigits: 0 })} ${t(
                      ".point",
                    )}`}</Typography>
                  </div>
                </Box>
              </Box>
            ),
            onOk: ({ close }: any) => {
              if (masterBenefitInsuranceRes) {
                close()
                const { masterBenefitInsuranceInEdit } = masterBenefitInsuranceRes
                const { masterBenefitInsurancePlans } = masterBenefitInsuranceInEdit

                const found = masterBenefitInsurancePlans.find(
                  (plan: any) => plan.masterInsurancePlan.id === insurance.id,
                )

                benefitInsuranceStore.setSelectedBenefitInsurancePlan(found.masterInsurancePlan.insuranceType.id, {
                  id: found.masterInsurancePlan.id,
                  masterInsurancePlan: found.masterInsurancePlan,
                  point: found.requiredPoints,
                })
              }
            },
            okButtonVariant: "contained",
            cancelButtonVariant: "outlined",
          })
        },
        [t, masterBenefitInsuranceRes],
      )

      const handleClickBack = useCallback(() => {
        paths.benefitCustomManagementPath(id).push()
      }, [id])

      useEffect(() => {
        if (selectedBenefitInsurancePlans && selectedBenefitInsurancePlans[insuranceTypeId]) {
          setSelected(selectedBenefitInsurancePlans[insuranceTypeId].masterInsurancePlan.id)
        } else if (masterBenefitInsuranceRes) {
          const { masterBenefitInsurancePlans } = masterBenefitInsuranceRes?.masterBenefitInsuranceInEdit

          const selectInsurancePlan = masterBenefitInsurancePlans.find(
            (plan: any) => plan.masterInsurancePlan.id === masterInsurancePlanId,
          )
          if (selectInsurancePlan) {
            benefitInsuranceStore.setSelectedBenefitInsurancePlan(
              selectInsurancePlan.masterInsurancePlan.insuranceType.id,
              {
                id: selectInsurancePlan.masterInsurancePlan.id,
                masterInsurancePlan: selectInsurancePlan.masterInsurancePlan,
                point: selectInsurancePlan.requiredPoints,
              },
            )
          }
        }
      }, [selectedBenefitInsurancePlans, masterInsurancePlanId, masterBenefitInsuranceRes, insuranceTypeId])

      return {
        indexOfSelectedInsurance,
        handleClickBack,
        handleSelect,
        insurances,
        selected,
        header: insuranceTypeName,
      }
    },
  ),
)

export const InsuranceSelectorPage = enhancer(InsuranceSelectorComponent)
