import { compose, withHooks } from "enhancers"
import { Box, Typography, Field, BrowseButton, FileUpload, IconButton, Notification } from "components"
import { get, range } from "lodash"
import styled from "styled-components/macro"

import { ReactComponent as TrashCan } from "assets/icon/trash_can_brown.svg"
import { BoxProps } from "@material-ui/core"

const AddButton = styled(BrowseButton)({
  backgroundColor: "#fff",
  borderColor: "#EE8B9B",
  color: "#EE8B9B",
  "&:hover": {
    backgroundColor: "#fff",
    color: "#EE8B9B",
  },
  "&.MuiButton-contained.Mui-disabled": {
    opacity: 0.4,
    color: "#EE8B9B",
    backgroundColor: "#fff",
  },
  "&.MuiButton-contained": {
    "& path": {
      fill: "#EE8B9B !important",
    },
  },
})

interface FileUploadProps extends BoxProps {
  simple: boolean
  title: string
  subTitle: string
  addButtonLabel: string
  amount: number
  name: string
  removeDocument: (idx: number) => void
  addDocument: () => void
}

const FileUploadList = (props: FileUploadProps) => (
  <Box {...props}>
    {props.simple ? (
      <>
        <AddButton startIcon="add" onBrowse={props.addDocument} mt={6}>
          <Typography color="#EE8B9B">{props.addButtonLabel}</Typography>
        </AddButton>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={6}>
              <Field component={FileUpload} name={`${props.name}[${index}]`} width={512} pl={4} simple />

              <Box display="flex" ml={2}>
                <IconButton color="inherit" onClick={() => props.removeDocument(index)}>
                  <TrashCan />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      </>
    ) : (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="body1" fontWeight="600" color="Primary/Brown">
              {props.title}
            </Typography>
            <Box mt={2}>
              <Typography variant="caption" color="Text/Dark">
                {props.subTitle}
              </Typography>
            </Box>
          </Box>

          <AddButton startIcon="add" height={40} mt={6} onBrowse={props.addDocument}>
            <Typography color="#EE8B9B">{props.addButtonLabel}</Typography>
          </AddButton>
        </Box>
        {range(props.amount).map((index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mt={2}>
              <Field
                component={FileUpload}
                name={`${props.name}[${index}]`}
                width="100%"
                justifyContent="space-between"
                // pl={4}
              />
              <Box display="flex" ml={2}>
                <IconButton color="inherit" onClick={() => props.removeDocument(index)}>
                  <TrashCan />
                </IconButton>
              </Box>
            </Box>
          )
        })}
      </>
    )}
  </Box>
)

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { form, name, insert, remove, title, addButtonLabel, mt, notDisplayHeader, simple, subTitle } = props
    const { useCallback } = hooks
    const amount = get(form.values, name)?.length ?? 0

    const addDocument = useCallback(
      (file: any) => {
        insert(amount, file)
      },
      [insert, amount],
    )

    const removeDocument = useCallback(
      (index: number) => {
        remove(index)
        Notification.notify("ลบไฟล์สำเร็จ", { variant: "success" })
      },
      [remove],
    )

    return {
      name,
      amount,
      addDocument,
      removeDocument,
      title,
      addButtonLabel,
      mt,
      notDisplayHeader,
      simple,
      subTitle,
    }
  }),
)

export default enhancer(FileUploadList)
